import { css } from "@emotion/react";
import { useAuth } from "@clerk/nextjs";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import { last } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { PageWrapper } from "~/components";
import { SignInModal, useSignInModal } from "~/components/SignInModal";
import { SignUpModal, useSignUpModal } from "~/components/SignUpModal";
import { api } from "~/utils/api";
import { prettyDate } from "~/utils/prettyDate";
import Image from "next/image";
import {
  MoreVert as MoreVertIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

const EntryMenu = ({ entryId }: { entryId: string }) => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            void router.push(`/entry/${entryId}`);
            handleClose();
          }}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            void router.push(`/entry/${entryId}?activity=think-deeper`);
            handleClose();
          }}
        >
          Think Deeper
        </MenuItem>
        <MenuItem
          onClick={() => {
            void router.push(
              `/entry/${entryId}?activity=additional-activities`
            );
            handleClose();
          }}
        >
          Additional Activities
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function Home() {
  const { isSignedIn, ...rest } = useAuth();
  const {
    openModal: openSignUpModal,
    closeModal: closeSignUpModal,
    isOpen: isSignUpModalOpen,
  } = useSignUpModal();
  const {
    openModal: openSignInModal,
    closeModal: closeSignInModal,
    isOpen: isSignInModalOpen,
  } = useSignInModal();
  const router = useRouter();

  useEffect(() => {
    if (!isSignedIn) {
      if (router.query?.modal === "sign-in") {
        if (isSignUpModalOpen) {
          closeSignUpModal();
        }
        if (!isSignInModalOpen) {
          openSignInModal();
        }
      } else if (router.query?.modal === "sign-up") {
        if (isSignInModalOpen) {
          closeSignInModal();
        }
        if (!isSignUpModalOpen) {
          openSignUpModal();
        }
      }
    }
  }, [
    closeSignInModal,
    closeSignUpModal,
    isSignInModalOpen,
    isSignUpModalOpen,
    isSignedIn,
    openSignInModal,
    openSignUpModal,
    router.query?.modal,
  ]);

  const [currentPage, setCurrentPage] = useState(
    Number((router.query.page as string) ?? 1)
  );
  useEffect(() => {
    if (
      router.query.page &&
      Number(router.query.page as string) !== currentPage
    ) {
      setCurrentPage(Number(router.query.page));
    }
  }, [currentPage, router.query.page]);
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    void router.replace({
      query: { ...router.query, page },
    });
  };
  const {
    data: { journalEntries, journalEntriesCount, pagesCount } = {},
    isInitialLoading: isInitialLoadingEntries,
    isFetching: isFetchingEntries,
  } = api.journals.getUserEntries.useQuery(
    {
      page: currentPage,
      pageSize: 10,
    },
    {
      enabled: !!isSignedIn,
      // TODO: remove this and use skeleton loading instead
      // TODO: prefetch the next page
      keepPreviousData: true,
    }
  );

  const topOfEntriesRef = useRef<null | HTMLDivElement>(null);

  if (isSignedIn === undefined) {
    return (
      <PageWrapper>
        <Box
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
          `}
        >
          <CircularProgress />
        </Box>
      </PageWrapper>
    );
  }

  return (
    <>
      <PageWrapper>
        {isSignedIn ? (
          <>
            <Box
              css={css`
                padding: 8px;
              `}
            >
              <Box ref={topOfEntriesRef}>
                <Typography
                  variant="h2"
                  css={css`
                    padding: 0 8px;
                  `}
                >
                  My Entries
                </Typography>

                {isInitialLoadingEntries ? (
                  <CircularProgress />
                ) : (
                  <Box
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <Typography
                      variant="body2"
                      css={css`
                        opacity: 0.6;
                        padding: 0 8px 8px;
                      `}
                    >
                      You have written {journalEntriesCount ?? "0"} so far.
                      {(journalEntriesCount ?? 0) > 10 && " Great job!"}
                    </Typography>

                    <Box
                      css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        opacity: ${isFetchingEntries ? 0.5 : 1};
                      `}
                    >
                      {journalEntries?.map((entry, index) => {
                        const latest = last(entry.history);
                        const hasThinkDeeper =
                          entry._count.thinkDeeperUserResponseHistory > 0;
                        const entryPreviewLength = 400;
                        return (
                          <Box
                            key={entry.id}
                            css={(theme) => css`
                              border: 1px solid #fcdad6;

                              &:hover {
                                background-color: ${theme.palette.action.hover};

                                .sub-action {
                                  border-top: 1px solid #f1695c;
                                }
                              }
                            `}
                          >
                            <Box
                              component="a"
                              onClick={() =>
                                void router.push(`/entry/${entry.id}`)
                              }
                              css={css`
                                flex: 1 1 auto;
                                display: block;
                                cursor: pointer;
                                padding: 8px 8px;
                                background-color: rgba(0, 0, 0, 0);
                              `}
                            >
                              <Box
                                css={css`
                                  display: flex;
                                  width: 100%;
                                  justify-content: space-between;
                                `}
                              >
                                <Typography
                                  variant="caption"
                                  css={css`
                                    opacity: 0.6;
                                  `}
                                >
                                  {prettyDate(latest?.createdAt)}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  css={css`
                                    opacity: 0.6;
                                  `}
                                >
                                  {!!entry.community?.name &&
                                    `Shared with ${entry.community.name}`}
                                </Typography>
                              </Box>
                              <Box
                                css={css`
                                  display: flex;
                                  min-height: 80px;
                                `}
                              >
                                <Box
                                  css={css`
                                    flex: 1 1 auto;
                                  `}
                                >
                                  <Typography
                                    variant="body1"
                                    className="highlight-mask"
                                    css={css`
                                      font-size: 1.1rem;
                                    `}
                                  >
                                    {entry.title}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    className="highlight-mask"
                                  >
                                    {latest?.rawText
                                      .slice(0, entryPreviewLength)
                                      .trimEnd()}
                                    {(latest?.rawText.length ?? 0) >
                                      entryPreviewLength && "..."}
                                  </Typography>
                                </Box>
                                <Box
                                  css={css`
                                    align-self: center;
                                    padding: 8px;
                                  `}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <EntryMenu entryId={entry.id} />
                                </Box>
                              </Box>
                            </Box>
                            {index === 0 && !hasThinkDeeper && (
                              <Box
                                onClick={() => {
                                  void router.push(
                                    `/entry/${journalEntries[0]?.id}?activity=think-deeper`
                                  );
                                }}
                                className="sub-action"
                                css={css`
                                  display: flex;
                                  align-items: center;
                                  border-top: 1px solid #fcdad6;
                                  background-color: #fcdad6;
                                  padding: 8px 24px 8px 8px;
                                  cursor: pointer;
                                `}
                              >
                                <Typography variant="body2">
                                  Dive deeper into your thoughts
                                </Typography>
                                <ChevronRightIcon
                                  css={css`
                                    color: #f1695c;
                                    margin-left: auto;
                                  `}
                                />
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                css={css`
                  display: flex;
                  justify-content: center;
                  padding: 16px;
                `}
              >
                <Pagination
                  siblingCount={0}
                  boundaryCount={0}
                  defaultPage={currentPage}
                  count={pagesCount ?? 0}
                  size="large"
                  showFirstButton
                  showLastButton
                  onChange={(_, page) => {
                    handleSetCurrentPage(page);
                    scrollTo({
                      top: (topOfEntriesRef.current?.offsetTop ?? 0) - 70,
                      behavior: "smooth",
                    });
                  }}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              gap: 16px;
              padding: 16px;
            `}
          >
            <Image
              src="/images/jordi-journaling.webp"
              width={400}
              height={400}
              alt="jordi-journaling"
              css={css`
                border-radius: 40%;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                margin: 0 auto;
                max-width: 80vw;
                max-height: 80vw;
              `}
            />
            <Typography
              variant="h1"
              css={css`
                width: 100%;
                text-align: center;
              `}
            >
              Welcome to Red Panda Journaling
            </Typography>
            <Typography
              variant="body1"
              css={css`
                width: 100%;
                text-align: center;
              `}
            >
              Journaling is a great way to reflect on your day, and to help you
              understand yourself better.
            </Typography>
            <Box
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                justify-content: center;
                align-items: baseline;
              `}
            >
              <Button onClick={openSignUpModal}>Sign up</Button>
              <Typography variant="body1">or</Typography>
              <Button onClick={openSignInModal}>Sign in</Button>
              <Typography
                variant="body1"
                css={css`
                  width: 100%;
                  text-align: center;
                  font-weight: 600;
                  margin-top: 16px;
                `}
              >
                Available features:
              </Typography>
              <Box
                component="ul"
                css={css`
                  max-width: 400px;
                  margin: 0 auto;
                  padding: 0 16px;
                `}
              >
                <li>Keep a private journal.</li>
                <li>
                  Receive custom prompts to help you dive even deeper into your
                  journaling to better understand your thoughts, feelings and
                  experiences.
                </li>
              </Box>
            </Box>
          </Box>
        )}
      </PageWrapper>
      <SignInModal />
      <SignUpModal />
    </>
  );
}
